body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media screen and (max-width: 600px) {
  .screen-600 {
    display: flex;
    flex-direction: column;
    margin: 1rem 0;
    width: 98% !important;
    align-items: center;
    justify-content: center;
    margin-left: 1rem !important;
  }
  .calculator {
    width: 100% !important;
  }
}
