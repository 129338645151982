.loadingParent{
    position: fixed;
  top: 0;
  left: 0;
    width: 100vw;
    height:100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.9);
    z-index: 10;
}

.loadingParent img{
  height: 18rem;
  
  width: 22rem;
  border-radius: 54% 55%;
}